
import { VisitExt } from "@/types";
import { TableState } from "ant-design-vue/lib/table/interface";
import {
  defineComponent,
  ref,
  Ref,
  watch,
  PropType,
  reactive,
  computed,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useAurero } from "../composable/useAurero";
import { CheckUserAgent } from "@hd2/common/src/utils";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { notification } from "ant-design-vue";

interface Table {
  columns: TableState["columns"];
}

export const ChooseVisitComponent = defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object as PropType<VisitExt>,
      default: () => {
        return {
          patients: [],
        };
      },
    },
    paymentType: {
      type: String,
      required: true,
    },
    redirectUrl: {
      type: String,
      default: "/",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const visible: Ref<boolean> = ref(false);
    const { getVisitUrl } = useAurero();
    const isSupported =
      !CheckUserAgent.isSafari() && !CheckUserAgent.isOperaMobile();
    const { showErrors } = useShowErrors();

    const table: Table = reactive({
      columns: [
        {
          title: t("CHOOSE_VISIT.HOUSE_VISITS.TABLE.FULLNAME"),
          dataIndex: "fullName",
          sorter: false,
          slots: { customRender: "fullName" },
        },
        {
          title: t("CHOOSE_VISIT.HOUSE_VISITS.TABLE.PESEL"),
          dataIndex: "pesel",
          sorter: false,
        },
        {
          title: t("CHOOSE_VISIT.HOUSE_VISITS.TABLE.PHONE_NUMBER"),
          dataIndex: "phone",
          sorter: false,
        },
        {
          title: t("CHOOSE_VISIT.HOUSE_VISITS.TABLE.STATUS"),
          dataIndex: "aureroVisitStatus",
          sorter: false,
          slots: { customRender: "aureroVisitStatus" },
        },
        {
          dataIndex: "startVisit",
          sorter: false,
          slots: { customRender: "startVisit" },
        },
      ],
      sorter: {},
    });

    watch(
      () => props.modelValue,
      (nv) => {
        visible.value = nv;
      }
    );

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text).then(
        function () {
          notification.open({
            message: t("CHOOSE_VISIT.OTHER_VISITS.COPY.SUCCESS"),
            class: "success",
          });
        },
        function () {
          notification.open({
            message: t("CHOOSE_VISIT.OTHER_VISITS.COPY.ERROR"),
            class: "error",
          });
        }
      );
    };

    const cancel = () => {
      emit("update:modelValue", false);
    };

    const goToVisit = async (id: number) => {
      if (isSupported) {
        router.push(
          `/visit/${id}${props.redirectUrl}${
            props.paymentType ? `&paymentType=${props.paymentType}` : ""
          }`
        );
      } else {
        try {
          const windowReference = window.open("#");
          await getVisitUrl(id.toString()).then((url: string) => {
            windowReference?.location.assign(url);
          });
        } catch (err: any) {
          showErrors(err);
        }
      }
      cancel();
    };

    return {
      cancel,
      visible,
      t,
      table,
      goToVisit,
      isSupported,
      type: computed(() => {
        switch (props.data.status) {
          case "FINISHED":
          case "CANCELLED_BY_DOCTOR":
          case "FINISHED_NO_CONTACT": {
            return "SHOW_DETAILS";
          }
          default: {
            return "START_VISIT";
          }
        }
      }),
      copyToClipboard,
    };
  },
});

export default ChooseVisitComponent;
